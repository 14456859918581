.image {
  max-width: 100%;
  max-height: 100%;
  padding: 2px;
}

.loader{
  animation: spin 1s infinite ease-in-out;
  color: rgba(255, 255, 255, 0.5);
  font-size: xx-large;
  text-align: center;
  -webkit-animation:spin 2s linear infinite;
  -moz-animation:spin 2s linear infinite;
  animation:spin 2s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.dialog {
  position: fixed;
  display:flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.774);
  height: 100vh;
  width: 100vw;
  top: 0px;
  bottom: 0px;
  overflow: hidden;
  z-index: 2;
}

.dialog > .bigImage {
  position: fixed;
  background: rgba(0, 0, 0, 1.0);
  max-height: 80vh;
  max-width: 90vw;
  bottom: 10vh;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  z-index: 100;
}
