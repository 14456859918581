.ImagePortfolio {
  text-align: left;
  font-family: 'Raleway', sans-serif;
  background-size: 50%;
  background-blend-mode: lighten;
  margin-top: 5vh;
  height: 100vh;
  max-width: 100vw;
  padding: 20px;
  opacity: 0;
  animation: active-ani3-out 0.4s;
  animation-timing-function: ease-in;
  grid-area: 1 / 1 / 2 / 2;
  z-index: -1;
}

.ImagePortfolio-text{
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.295), rgba(31, 31, 31, 0.295), rgba(37, 37, 37, 0.295));
  padding: 10px;
  width: 50%;
  height:min-content;
  border-radius: 1%;
  box-shadow: burlywood;
}
.actives-portfolio{
  opacity: 1;
  z-index: 1;
}

.image-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}

.image-wrapper > div {
  flex: content;
  max-width: 100%;
  height: 300px;
  &:hover{
    cursor: pointer;
  }
}


/* Too narrow to support three columns */
@media all and (max-width: 640px) {
  .image-wrapper > div {
      flex-flow:column wrap;  
      height:120%;
  }  
  .ImagePortfolio-text {
    width: 100%;
  }  
}

@media all and (min-width: 1400px) { 
  .image-wrapper > div {
    height: 400px;
  } 
  .ImagePortfolio-text {
    width: 100%;
  }   
}
