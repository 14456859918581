$pColor: #bd894a;
$headerColor: #d89e56;

body {
  display: flex;
  justify-content: flex-start;
  background-color: black;
  &.lightmode {
    background-color: rgb(255, 251, 248);
  }
}

p {
  color: $pColor;
  font-size: 16px;
  @media all and (max-width: 640px) {
    font-size: 21px;
  }
}

h1 {
  color: $headerColor;
  @media all and (max-width: 640px) {
    font-size: 47 px;
  }
}

.App {
  text-align: center;
  width: 100vw;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: $headerColor;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#first {
  position: relative;
  height: 100vh;
}

#second {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: rgb(151, 127, 96);
}

.link {
  color: #f3ba75;
  text-decoration: none;
  transition: text-decoration 0.4s ease-in;
  &:hover {
    text-decoration: underline #7e4d10;
  }
}
