$orangeColor: #bf6b04;
$size: 25vw;
@media all and (max-width: 640px) {
	$size: 90vw;
}

.card {
	$delay: 0.5s;
	$duration: 1s;
	$timing: cubic-bezier(0.51, 0.92, 0.24, 1);
	position: relative;
	color: $orangeColor;
	text-decoration: none;
	overflow: hidden;
	transition-property: color;
	transition-delay: $delay;
	box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
	&__head {
		position: relative;
		display: flex;
		justify-content: space-evenly;
		padding: 10%;
		--size: 20vw;
		--multiplied: 1.5;
		width: var(--size);
		height: calc(var(--size) * var(--multiplied));
		font-weight: bold;
		@media all and (max-width: 640px) {
			--size: 90vw;
			--multiplied: 0.4;
			margin-bottom: 2em;
			padding: 0;
		}
	}
	&__author {
		position: absolute;
		padding: 3px;
		left: 0;
		right: 0;
		bottom: 20px;
		color: $orangeColor;
		background-color: rgba(0, 0, 0, 0.719);
		transition: background-color 0.2s ease-in-out;
		@media all and (max-width: 640px) {
			bottom: 5px;
		}
		&:hover{
			background-color: rgba(0, 0, 0, 1);
		}
	}
	&:hover {
		color: white;
		transition-delay: 0;
		background-color: rgba(0, 0, 0, 1);
	}

	&.hover &__author {
		transition-delay: 0;
	}

	&__image_work {
		position: absolute;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
		transform-origin: top center;
		transition-property: transform;
		transition-delay: $delay;
		background-image: url(../../Media/jobb.jpg);
	}
	&__image_sport {
		position: absolute;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
		transform-origin: top center;
		transition-property: transform;
		transition-delay: $delay;
		background-image: url(../../Media/sport.jpg);
	}
	&__image_education {
		position: absolute;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
		transform-origin: top center;
		transition-property: transform;
		transition-delay: $delay;
		//background-image: url(../../Media/education.jpg);
	}
	&__headline {
		font-weight: 400;
		margin: 0 0 0.8em;
	}

	&__text {
		line-height: 1.5;
		margin: 0;
		opacity: 0.8;
	}

	&__foot {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 0 2em 2em;
		opacity: 0;
		transition-property: opacity;
	}

	&.hover &__foot {
		opacity: 1;
		transition-delay: $delay;
	}

	&__link {
		color: currentColor;
		text-decoration: none;
		border-bottom: 2px solid #b5272d;
	}
}

.author {
	display: flex;
	align-items: center;

	&__image {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		width: 80px;
		height: 80px;
		overflow: hidden;
	}

	&__content {
		display: grid;
		grid-gap: 0.4em;
		font-size: 0.9em;
	}

	&__header {
		margin: 0;
		font-weight: 600;
	}

	&__subheader {
		margin: 0;
		opacity: 0.8;
	}
}
