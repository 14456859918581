.Start {
    text-align: left;
    font-family: 'Raleway', sans-serif;
    background: url('../../Media/start.webp') no-repeat center;
    background-size: 60%;
    background-position: right;
    background-blend-mode: lighten;
    margin-top: 5vh;
    height: 90vh;
    width: 100vw;
    padding: 20px;
    opacity: 0;
    grid-area: 1 / 1 / 2 / 2;
    z-index: -1;
    overflow: hidden;
}

.Start-text {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.295), rgba(31, 31, 31, 0.295), rgba(37, 37, 37, 0.295));
  padding: 10px;
  padding-top: 40px;
  width: 50%;
  height:min-content;
  border-radius: 1%;
  box-shadow: burlywood;
  top: 10%;
}

/* Too narrow to support three columns */
@media all and (max-width: 640px) {
  .Start-text {
    width: 100%;
  } 
  .Start {
    background: url('../../Media/start.jpg') no-repeat bottom;
    background-size: 60%;
    background-position: right bottom;
    background-blend-mode: lighten;
  }   
}

.actives-start{
    opacity: 1;
    z-index: 1;
}
