.CV {
  display: flex;
  justify-content:space-between;
  align-items: center;
  flex-wrap: wrap;
  text-align: left;
  font-family: 'Raleway', sans-serif;
  background-size: 50%;
  background-position: right;
  background-blend-mode: lighten;
  margin-top: 1vh;
  height: 100vh;
  width: 100vw;
  padding: 20px;
  opacity: 0;
  grid-area: 1 / 1 / 2 / 2;
  z-index: -1;
}
.CV-text {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.295), rgba(31, 31, 31, 0.295), rgba(37, 37, 37, 0.295));
  padding: 10px;
  width: 80%;
  height:min-content;
  border-radius: 1%;
  box-shadow: burlywood;
  @media all and (max-width: 640px) {
    width: 100%;
  }
}

.cards{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
}
/* Too narrow to support three columns */
@media all and (max-width: 640px) {
  .cards {
    flex-direction: column;
  }    
}

.actives-cv{
  opacity: 1;
  z-index: 1;
}

#work{
  height: 100vw;
  width: 100%;
  background-color: rgb(0, 0, 0);
}
#sport{
  display: flex;
  height: 100vw;
  width: 100%;
  background-color: black;
}
#education{
  display: flex;
  height: 100vw;
  width: 100%;
  background-color: black;
}

.cv-menu{
  height: inherit;
  width: 100%;
  margin-bottom: 130px;
}
